<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { apiRequest } from "@/helpers/api-call";
import router from "@/router/index.js";
// import { CPagination } from '@coreui/vue';
// import '@coreui/coreui/dist/css/coreui.min.css'


//import router from "@/router/index.js";

/**
 * Form validation component
 */
export default {
  page: {
    title: "Recherche de course",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      tableItems: [],
      title: "Recherche de course",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Recherche de course",
          active: true,
        },
      ],
      thenDay: "",
      id: "",
      totalCourse: 0,
      totalInitCourse: 0,
      totalRows: null,
      currentPage: 1,
      perPage: 100,
      currentPageI: 1,
      perPageI: 100,
      pageOptions: [500, 1000, 2000],
      filter: null,
      filterOn: [],
      courseData: [],
      initCourse: [],
      alerteDataDes: [],
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        { key: "client", label: "Client" },
        { key: "phone", label: "Téléphone" },
        { key: "driverAround", label: "Conducteurs proches" },
        { key: "driverReached", label: "Conducteurs atteints" },
        { key: "acceptedBy", label: "Accepté par" },
        { key: "lieuDepart", label: "Départ" },
        { key: "lieuArrive", label: "Arrivée" },
        { key: "distance", label: "Distance (Km)" },
        { key: "dateCreation", label: "Date" },
        { key: "prixFinal", label: "Prix de la course" },
        { key: "moyenTransport", label: "Moyen de transport" },
      ],
      fieldsI: [
        { key: "client", label: "Client" },
        { key: "phone", label: "Téléphone" },
        { key: "lieuDepart", label: "Départ" },
        { key: "lieuArrivee", label: "Arrivée" },
        { key: "distances", label: "Distance (Km)" },
        { key: "createdAt", label: "Date" },
        { key: "prix", label: "Prix Estimés" },
      ],

      startValue: 1,
      endValue: 500,
      totalValue: "0",
      userChargeLoader: false,
      newDatTrajetRechercher: [],
      newStart: false,
      showdisable: false,
      parBon: 500,
      history: {
        id: null,
        user: null,
      },
      deleteModal: false,
      foundData: [],
      foundDataI: [],
      keyWord: '',
      isLoading: false,
      displayedData: [],
      keyWordI:'',

    };
  },

  // created() {
  //   this.fetchData();
  // },


  async mounted() {

    this.fetchData();
    // this.initData()
    this.initSearch();

  },


  computed: {
    filteredData() {
      if (!this.keyWord) return this.courseData;
      const keyword = this.keyWord.toLowerCase();
      return this.displayedPages.filter(item =>
        item.passager.personne.lastname.toLowerCase().includes(keyword) ||
        item.passager.personne.firstname.toLowerCase().includes(keyword) ||
        (item.passager.personne.indicatifTel + item.passager.personne.tel).includes(keyword)
      );
    },
    filteredDataI() {
      if (!this.keyWordI) return this.initCourse;
      const keyword = this.keyWordI.toLowerCase();
      return this.initCourse.filter(item =>
        item.passager.personne.lastname.toLowerCase().includes(keyword) ||
        item.passager.personne.firstname.toLowerCase().includes(keyword) ||
        (item.passager.personne.indicatifTel + item.passager.personne.tel).includes(keyword)
      );
    },
    paginatedData() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = start + this.perPage;
      return this.filteredData.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.totalRows / this.perPage);
    },
    totalPagesI() {
      return Math.ceil(this.totalInitCourse / this.perPageI);
    },
    displayedPages() {
      const range = 2; // Nombre de pages à afficher de chaque côté de la page courante
      let start = Math.max(1, this.currentPage - range);
      let end = Math.min(this.totalPages, this.currentPage + range);

      if (start === 1) end = Math.min(5, this.totalPages);
      if (end === this.totalPages) start = Math.max(1, this.totalPages - 4);

      return Array.from({ length: (end - start + 1) }, (_, i) => start + i);
    },

    displayedPagesI() {
      const range = 2; // Nombre de pages à afficher de chaque côté de la page courante
      let start = Math.max(1, this.currentPageI - range);
      let end = Math.min(this.totalPagesI, this.currentPageI + range);

      if (start === 1) end = Math.min(5, this.totalPagesI);
      if (end === this.totalPagesI) start = Math.max(1, this.totalPagesI - 4);

      return Array.from({ length: (end - start + 1) }, (_, i) => start + i);
    }
  },

  watch: {
    keyWord() {
      this.recherche();
    }
  },


  methods: {

    goToProfilClient(id) {

      router.push(`/user/user_info/${id}`)

    },
    async fetchData() {
      this.isLoading = true;
      try {
        const request = await apiRequest(
          "GET",
          `courses-historique-recherche?limit=${this.perPage}&page=${this.currentPage}`,
          false,
          undefined
        );

        if (request && request.data) {
          this.courseData = request.data.courses;
          this.totalRows = request.data.total;
          console.log('this.courseData ', this.courseData)

        }
      } catch (error) {
        console.error("Error fetching course data:", error);
      } finally {
        this.isLoading = false;
      }
    },
    async initSearch() {
      const request = await apiRequest(
        "GET",
        `admin/recherches-vtc?limit=${this.perPageI}&page=${this.currentPageI}`,
        undefined,
        false
      );

      if (request && request.data) {
        console.log("les données du trajet", request.data)

        this.initCourse = request.data.rechercheFound;


        this.totalInitCourse = request.data.total;
        console.log("this.courseData initCourse", this.initCourse)
      }
    },


    //     updateDisplayedData() {
    //   this.$nextTick(() => {
    //     this.displayedData = [...this.courseData];
    //   });
    // },
    async changePage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
        await this.fetchData();
      }
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString() + ' - ' + date.toLocaleTimeString("FR-fr");
    },

    async changePageI(page) {
      if (page >= 1 && page <= this.totalPagesI) {
        this.currentPageI = page;
        await this.initSearch();
      }
    },

   
    async rechercheI() {
      if (this.keyWordI == '' )return await this.initSearch();
      this.keyWordI = this.keyWordI.toLowerCase()
      console.log("initCourse", this.initCourse);
      this.initCourse = this.initCourse.filter(data => data.user.lastname.toLowerCase().includes(this.keyWordI) ||
        data.user.firstname.toLowerCase().includes(this.keyWordI) ||
        (data.user.indicatifTel + data.user.tel).includes(this.keyWordI) ||
        (data.user.lastname.toLowerCase() + " " + data.user.firstname.toLowerCase()).includes(this.keyWordI))
        if (this.initCourse.length==0 )return await this.initSearch();


      //console.log("foundI ", this.foundDataI)
    },
    async recherche() {
      await this.fetchData();
      if (this.keyWord !='') {
        this.keyWord = this.keyWord.toLowerCase()

      this.courseData = this.courseData.filter(data => data.passager.personne.lastname.toLowerCase().includes(this.keyWord) ||
        data.passager.personne.firstname.toLowerCase().includes(this.keyWord) ||
        (data.passager.personne.indicatifTel + data.passager.personne.tel).includes(this.keyWord) ||
        (data.passager.personne.lastname.toLowerCase() + " " + data.passager.personne.firstname.toLowerCase()).includes(this.keyWord))


      console.log("found ", this.courseData)
        
      }else{
        this.currentPage = 1;
        this.fetchData()
      }
      
    },


    /**Paginaiton**/



    goToUserDetail(id) {
      //const routeData = router.resolve({name: 'infoUserPage', params:{idUser:id}});
      //router.push(`/user/user_info/${id}`);
      window.open(`/user/user_info/${id}`, '_blank', 'noreferrer');

      // window.open(routeData.href, '_blank');
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="containerDot" v-if="courseData.length == 0">
            <span class="loading">Chargement en cours... </span>
            <!--  <div class="dots">
              <div class="dot dot-1"></div>
              <div class="dot dot-2"></div>
              <div class="dot dot-2"></div>
            </div> -->
          </div>
          <div class="d-flex align-items-center otherThing" v-if="userChargeLoader === true">
            <span class="loading">Chargement en cours...</span>
            <div aria-hidden="true"></div>
          </div>
          <div class="card-body">
            <div class="row d-flex justify-content-between align-items-center p-2">
              <div>
                <!-- <h4 class="card-title mt-4">
                  Total recherches : {{ foundData.length != 0
      ? foundData.length
      : courseData.length }}
                </h4> -->
              </div>
              <div>
                <!-- <b-pagination v-model="currentPage" :total-rows="foundData.length != 0
      ? foundData.length
      : courseData.length" :per-page="perPage" :limit="2" first-text="◀" last-text="▶"
                  aria-controls="my-table">></b-pagination> -->
              </div>
            </div>
            <form>
              <b-tabs nav-class="nav-tabs-custom">

                <b-tab title-link-class="p-3">
                  <template v-slot:title>
                    <a class="font-weight-bold active">Courses recherchées non créées {{ foundDataI.length != 0
      ? foundDataI.length
      : totalInitCourse }}</a>
                  </template>

                  <!-- Table initSearch -->
                  <div>
                    <div class="row mt-3 d-flex justify-content-between ">
                      <!-- Barre de recherche -->
                      <div class="mb-3 col-md-4">
                        <input type="text" class="form-control" v-model="keyWordI" @input="rechercheI"
                          placeholder="Rechercher..." />
                      </div>
                      <nav class="mb-3">
                      <ul class="pagination">
                        <li class="page-item" :class="{ disabled: currentPageI === 1 }">
                          <a class="page-link" href="#" @click.prevent="changePageI(currentPageI - 1)">Précédent</a>
                        </li>
                        <li v-for="page in displayedPagesI" :key="page" class="page-item"
                          :class="{ active: currentPageI === page }">
                          <a class="page-link" href="#" @click.prevent="changePageI(page)">{{ page }}</a>
                        </li>
                        <li class="page-item" :class="{ disabled: currentPageI === totalPagesI }">
                          <a class="page-link" href="#" @click.prevent="changePageI(currentPageI + 1)">Suivant</a>
                        </li>
                      </ul>
                    </nav>
                      </div>
                    <table class="table table-striped table-hover">
                      <thead>
                        <tr>
                          <th style="color:white" v-for="field in fieldsI" :key="field.key">{{ field.label }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in initCourse" :key="item.id">
                          <td @click="goToProfilClient(item.user.id)" style="cursor: pointer;">
                            {{ item.user.lastname }} {{ item.user.firstname }}
                          </td>
                          <td>
                            <a :href="'https://wa.me/' + item.user.indicatifTel + item.user.tel" class="text-success"
                              target="_blank">
                              {{ item.user.indicatifTel }} {{ item.user.tel }}
                            </a>
                          </td>
                          <td>{{ item.lieuDepart }}</td>
                          <td>{{ item.lieuArrivee }}</td>
                          <td>{{ (item.details.distances ).toFixed(2)}}</td>
                          <td>{{ formatDate(item.createdAt) }}</td>
                          <td>
                            <div v-for="(el, index) in item.details.margePrix" :key="index">
                              <span>{{ el.nbr_driver }}{{ el.moyenTransport }} -> {{ el.prix }}, </span>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <!-- Pagination pour la table initSearch -->
                    <nav>
                      <ul class="pagination">
                        <li class="page-item" :class="{ disabled: currentPageI === 1 }">
                          <a class="page-link" href="#" @click.prevent="changePageI(currentPageI - 1)">Précédent</a>
                        </li>
                        <li v-for="page in displayedPagesI" :key="page" class="page-item"
                          :class="{ active: currentPageI === page }">
                          <a class="page-link" href="#" @click.prevent="changePageI(page)">{{ page }}</a>
                        </li>
                        <li class="page-item" :class="{ disabled: currentPageI === totalPagesI }">
                          <a class="page-link" href="#" @click.prevent="changePageI(currentPageI + 1)">Suivant</a>
                        </li>
                      </ul>
                    </nav>
                  </div>



                </b-tab>

                <b-tab title-link-class="p-3">
                  <template v-slot:title>
                    <a class="font-weight-bold active">Liste des courses recherchées {{ foundData.length != 0
      ? foundData.length
      : courseData.length }}</a>
                  </template>


                  <div>
                    <div class="row mt-3 d-flex justify-content-between ">
                      <!-- Barre de recherche -->
                      <div class="mb-3 col-md-4">
                        <input type="text" class="form-control" v-model="keyWord"  @input="recherche"
                          placeholder="Rechercher..." />
                      </div>
                      <!-- Pagination -->
                      <nav class="col-md-4 mb-3">
                        <ul class="pagination">
                          <li class="page-item" :class="{ disabled: currentPage === 1 }">
                            <a class="page-link" href="#" @click.prevent="changePage(currentPage - 1)">Précédent</a>
                          </li>
                          <li v-for="page in displayedPages" :key="page" class="page-item"
                            :class="{ active: currentPage === page }">
                            <a class="page-link" href="#" @click.prevent="changePage(page)">{{ page }}</a>
                          </li>
                          <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                            <a class="page-link" href="#" @click.prevent="changePage(currentPage + 1)">Suivant</a>
                          </li>
                        </ul>
                      </nav>




                    </div>

                    <!-- Table -->
                    <table class="table table-striped table-hover">
                      <thead>
                        <tr>
                          <th style="color:white" class="pt-2" v-for="field in fields" :key="field.key">{{ field.label
                            }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in courseData" :key="item.id">
                          <td>{{ item.passager.personne.lastname }} {{ item.passager.personne.firstname }}</td>
                          <td>
                            <a :href="'https://wa.me/' + item.passager.personne.indicatifTel + item.passager.personne.tel"
                              class="text-success" target="_blank">
                              {{ item.passager.personne.indicatifTel }} {{ item.passager.personne.tel }}
                            </a>
                          </td>
                          <td>
                            <div v-if="item.conducteur_a_proximite && item.conducteur_a_proximite.length">
                              <div v-for="(driver, index) in item.conducteur_a_proximite" :key="index">
                                {{ driver.personne.firstname }} {{ driver.personne.lastname }}
                                (<a :href="'https://wa.me/' + driver.personne.indicatifTel + driver.personne.tel"
                                  class="text-success" target="_blank">
                                  {{ driver.personne.indicatifTel }} {{ driver.personne.tel }}
                                </a>)
                              </div>
                            </div>
                            <div v-else>Aucun conducteur</div>
                          </td>
                          <td>
                            <div
                              v-if="item.conducteurs_ayant_recu_demande && item.conducteurs_ayant_recu_demande.length">
                              <div v-for="(driver, index) in item.conducteurs_ayant_recu_demande" :key="index">
                                <span v-if="driver.conducteur && driver.conducteur.personne">
                                  {{ driver.conducteur.personne.firstname }} {{ driver.conducteur.personne.lastname }}
                                  (<a
                                    :href="'https://wa.me/' + driver.conducteur.personne.indicatifTel + driver.conducteur.personne.tel"
                                    class="text-success" target="_blank">
                                    {{ driver.conducteur.personne.indicatifTel }} {{ driver.conducteur.personne.tel }}
                                  </a>)
                                </span>
                              </div>
                            </div>
                            <div v-else>Aucun conducteur</div>
                          </td>
                          <td>
                            <div v-if="item.conducteur">
                              {{ item.conducteur.personne.firstname }} {{ item.conducteur.personne.lastname }}
                              (<a
                                :href="'https://wa.me/' + item.conducteur.personne.indicatifTel + item.conducteur.personne.tel"
                                class="text-success" target="_blank">
                                {{ item.conducteur.personne.indicatifTel }} {{ item.conducteur.personne.tel }}
                              </a>)
                            </div>
                            <div v-else>Aucun</div>
                          </td>
                          <td>{{ item.lieuDepart }}</td>
                          <td>{{ item.lieuArrive }}</td>
                          <td>{{ item.distance }}</td>
                          <td>{{ formatDate(item.dateCreation) }}</td>
                          <td>{{ item.prixFinal }}</td>
                          <td>{{ item.moyenTransport }}</td>
                        </tr>
                      </tbody>
                    </table>

                    <!-- Pagination -->
                    <nav>
                      <ul class="pagination">
                        <li class="page-item" :class="{ disabled: currentPage === 1 }">
                          <a class="page-link" href="#" @click.prevent="changePage(currentPage - 1)">Précédent</a>
                        </li>
                        <li v-for="page in displayedPages" :key="page" class="page-item"
                          :class="{ active: currentPage === page }">
                          <a class="page-link" href="#" @click.prevent="changePage(page)">{{ page }}</a>
                        </li>
                        <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                          <a class="page-link" href="#" @click.prevent="changePage(currentPage + 1)">Suivant</a>
                        </li>
                      </ul>
                    </nav>
                  </div>




                </b-tab>
              </b-tabs>
            </form>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>

<style>
thead {
  background: #3AAA35;
}

thead div {
  color: #fff;
}

.input {
  right: 13px;
  position: absolute;
  display: flex;
  justify-content: center;
  width: 200px;
  height: 35px;
  top: -50%;

  flex: 60%;

  box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 10%), 0px 1px 3px 0px rgb(0 0 0 / 8%);
  background: #ffffff;
}

.input input {
  padding: 3px 3px 3px 8px;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 18px;
  color: #495057;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
}

.input input:focus {
  border: 3px solid #3aaa35;
}

.inputCol {
  position: relative;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #3AAA35;
  border-color: #fff;
}

.loading {
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
}

.loading {
  font-weight: bold;
  font-size: 1.001rem;
  width: 100%;
  white-space: nowrap;
  color: hsl(0, 0%, 12%);
  text-align: center;
  display: inline-block;
  margin: 0 auto !important;
}

.otherThing {
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  width: 250px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -50px;
}

.containerDot {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
  width: 250px;
  height: 40px;
  position: absolute;
  top: -13%;
  left: 0;
  right: 0;
  margin: 0 auto;
}

ion-icon {
  cursor: pointer;
}

.myBouton {
  background: transparent;
  text-align: center;
  outline: none;
  border: none;
}

.containerDot {
  z-index: 100;
}

.paging_simple_numbers {
  background: #3AAA35;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  padding: 10px 30px;

  box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15),
    -6px -6px 10px -1px rgba(255, 255, 255, 0.7);
}

.paging_simple_numbers ul li {
  color: white !important;
}


.paging_simple_numbers ul li button {
  color: #fff !important;
}
</style>
